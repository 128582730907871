<template>
  <div class="jobhunter">
    <div class="top" v-if="indexshow">
      <div class="names">{{Name}}</div>
    </div>
    <div v-if="indexshow" style="position: relative;top:-110px">
      <div class="center">
        <van-cell title="中介超市" to="/huayang" is-link />
        <van-cell title="知识大讲堂" to="/huayang/classroom" is-link />
        <van-cell title="资源看板" to="/huayang/serve" is-link />
        <van-cell title="中介服务评论" to="/huayang/comment" is-link />
        <van-cell title="发布资源清单" to="/huayang/resource" is-link />
        <van-cell title="区企互动" to="/huayang/interaction" is-link />
        <van-cell title="联系平台" to="/huayang/lianluo" is-link />
        <van-cell title="修改密码" @click="change" is-link />
        <van-cell title="退出登录" @click="quit" is-link />
      </div>
    </div>
    <div v-if="passwordshow">
      <div style="width:95%;margin:10px 2.5%">
        <van-field v-model="ChangePwd.opwd" required label="旧密码" />
      <van-field v-model="ChangePwd.npwd" required type="password" label="新密码" />
      <van-field v-model="ChangePwd.npwds" required type="password" label="重复密码" />
      </div>
      <van-button @click="changePassword " round type="info" style="width: 80%;margin: 15px 10%;">提 交</van-button>
      <van-button @click="changes " round style="width: 80%;margin: 0px 10%;">返 回</van-button>
    </div>
  </div>
</template>
<script>
import Vue from 'vue';
import { Toast } from 'vant';
Vue.use(Toast);
import { getaccToken, setaccToken,removeaccToken } from "@/utils/auth";
import { RE_GetMyEnterprise,ChangePwd } from "@/api/REplat";
export default {
  data(){
    return{
      ChangePwd:{
        opwd:"", //旧密码、
        npwd:"", //新密码
        npwds:"",
      },
      indexshow:true,
      passwordshow:false,
      list:[],
      Name:"",
    }
  },
  created(){
    // this.LoginSeeker();
  },
  mounted(){
    var getToken = JSON.parse(getaccToken());
    this.Name = getToken.uName;
  },
  methods:{
    LoginSeeker(){  //获取当前登录用户
      RE_GetMyEnterprise({accToken:getaccToken()}).then((res)=>{
        console.log(res);
        this.list = res.data.data;
      })
    },
    quit(){  //退出登录
      removeaccToken();
      this.$router.push({
        name:'home' ,
      })
    },
    change(){ //修改密码显示
      this.indexshow  = false;
      this.passwordshow  = true;
    },
    changes(){
      this.indexshow  = true;
      this.passwordshow  = false;
    },
    changePassword(){ //修改密码
    if(this.ChangePwd.opwd == ""){
      Toast.fail('旧密码不能为空!');
      return false;
    }
    if(this.ChangePwd.npwd == ""){
      Toast.fail('新密码不能为空!');
      return false;
    }
    if(this.ChangePwd.npwds == ""){
      Toast.fail('请再次输入密码!');
      return false;
    }
    if(this.ChangePwd.npwd != this.ChangePwd.npwds){
      Toast.fail('两次密码不同!');
      return false;
    }
    this.ChangePwd.accToken = getaccToken();
      ChangePwd(this.ChangePwd).then((res)=>{
         console.log(res);
        if(res.data.code == 0){
          Toast.success('修改成功!');
          removeaccToken();
          this.$router.push({
        name:'firmlogin' ,
      })
        }else {
          Toast.fail(res.data.msg);
        }
      })
    },
  }
}
</script>
<style scoped>
.jobhunter {
  background: rgb(245, 242, 242);
  height: 100%;
}
.jobhunter .top {
  position: relative;
  width: 100%;
  height: 200px;
  background: #1b80ec;
  border-bottom-right-radius: 30px;
  border-bottom-left-radius: 30px;
}
.jobhunter .center{
  width: 85%;
  margin: 10px auto;
  background: #fff;
  border-radius: 15px;
  padding: 15px;
}
.top .names {
  position: absolute;
  top: 30px;
  width: 90%;
  margin: 0px 5%;
  overflow: hidden;
  font-size: 22px;
  color: #fff;
  text-align: center;
}
</style>